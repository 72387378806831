import React, { useContext } from 'react';
// import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';
import { GlobalDataContext } from "../../context/context";

const Banner = (props) => {
	const { rpdata } = useContext(GlobalDataContext);

    return (
		<div className="banner-area banner-area-1 banner-area-bg pt-100 position-relative fondovideo">
		<video
		  className="video-background"
		  autoPlay
		  muted
		  loop
		  id="myVideo"
		>
		  <source
			src="https://firebasestorage.googleapis.com/v0/b/videospromo-24754.appspot.com/o/Colorado%20Springs%20Stock.mp4?alt=media&token=04ca571e-fec4-4ed9-8e58-2723eb551a74"
			type="video/mp4"
		  />
		</video>
		<div className="container d-flex flex-column flex-md-row w-80 mx-auto position-absolute">
		  <div className="banner-inner text-center">
			<p>{rpdata?.dbSlogan?.[0].slogan}</p>
			<div className="line" />
			<h2>{rpdata?.dbSlogan?.[1].slogan}</h2>
		  </div>
		</div>
	  </div>
    );
        }

export default Banner